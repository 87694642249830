.App {
  text-align: center;
  /* font-size: 23px; */
}

html {
  scroll-behavior: smooth;
}

/* Reset some default styles */
body, h1, h2, h3, p, ul {
  margin: 0;
  padding: 0;
}



header {
  background-color: #f2f2f2;
  padding: 10px 0;
}


/* Add more styles as needed */
