:root {
    --theme-color: #68A5B3;
}

.topbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    /* horizontal offset, vertical offset, blur radius, spread radius, color */
}

.topbar>.contents {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1%
}

/* .topbar > .contents > .logo {
    width: 40px;
    height: 40px;
} */


.topbar>.contents>.links>a {
    text-decoration: none;
    color: black;
    margin-left: 45px;
    font-size: 20px;
    font-weight: 600;
}

.topbar>.contents>.links>a:hover {
    color: var(--theme-color);
}

.about {
    margin-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.about>img {
    max-width: 300px;
    width: 100%;
    border-radius: 10px;
}

.about>.contents {
    margin: 30px 0 0 0;
    text-align: left;
    font-size: 17px;
    font-weight: 500;
}

.about>.contents>.greeting {
    font-weight: 700;
    font-size: 23px;
    margin-bottom: 20px;
}

.about>.contents>.description>a {
    text-decoration: none;
    color: var(--theme-color);
    font-weight: 600;
}

.about>.contents>.icons {
    margin-top: 12px;
}

.about>.contents>.icons>a>img {
    width: 28px;
    height: 28px;
    padding: 8px;
    transition: all 0.2s linear;
}

.about>.contents>.icons>a>img:hover {
    transform: scale(1.2);
}

.projects {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.projectlist {
    display: flex;
    flex-direction: column;
}

.project {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
}

.project>img {
    width: 100%;
    margin-bottom: 10px;
}

.project>.projectcontents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.project>.projectcontents>.title {
    display: inline;
    font-size: 28px;
    margin-bottom: 8px;
}

.project>.projectcontents>.title>.year {
    font-weight: 400;
}

.project>.projectcontents>.authors {
    /* font-style: italic; */
    margin-bottom: 12px;
}

.project>.projectcontents>.authors>.authorholder>.highlight_person {
    font-weight: 600;
}

.publications {
    /* margin-top: 30px; */
    width: 100%;
    display: flex;
    flex-direction: column;
}

.publications-header {
    font-size: 28px;
    color: gray;
    font-weight: 800;
    margin-bottom: 8px;
}

.equal-contribution {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.publicationlist {
    display: flex;
    flex-direction: column;
}

.footer {
    margin-top: 50px;
    display: flex;
    height: 80px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}

.title {
    font-size: 28px;
    font-weight: 800;
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
}


@media (min-width: 708px) {
    .topbar>.contents {
        padding: 0;
        width: 80%;
    }

    .about {
        margin-top: 90px;
        width: 100%;
        flex-direction: row;
    }

    .about>img {
        width: 250px;
        height: 250px;
        border-radius: 10px;
    }

    .about>.contents {
        margin: 0 0 0 30px;
        text-align: left;
    }

    /* .projects {
        margin-top: 70px;
        width: 660px;
    }
    .project {
        flex-direction: row;
    }
    .project > img {
        width: 300px;
        margin-right: 24px;
        margin-bottom: 0px;
    } */
    .publications {
        margin-top: 40px;
        width: 100%;
    }
}

@media (min-width: 1008px) {
    .topbar>.contents {
        padding: 0;
        width: 80%;
    }

    .topbar>.contents>.name {
        flex: 1
    }

    .topbar>.contents>.link {
        flex: 3.5
    }

    .about {
        margin-top: 90px;
        width: 100%;
        flex-direction: row;
    }

    .about>img {
        width: 300px;
        height: 300px;
        border-radius: 10px;
    }

    .about>.contents {
        margin: 0 0 0 30px;
        text-align: left;
    }

    /* .projects {
        margin-top: 70px;
        width: 960px;
    }
    .project {
        flex-direction: row;
    }
    .project > img {
        width: 360px;
        margin-right: 24px;
        margin-bottom: 0px;
    } */
    .publications {
        margin-top: 40px;
        width: 100%;
    }
}