@tailwind base;
@tailwind components;
@tailwind utilities;
@import "@fontsource/fira-code";

body {
  margin: 0;
  font-family: "Source Sans 3", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Fira Code', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #68A5B3;
  --secondary-color: #FFF1BF;
}

@layer utilities {
  .bg-primary {
    background-color: var(--primary-color);
  }

  .text-primary {
    color: var(--primary-color);
  }

  .bg-secondary {
    background-color: var(--secondary-color);
  }

  .text-secondary {
    color: var(--secondary-color);
  }

  .text-stroke-white {
    -webkit-text-stroke: 1px white;
    text-stroke: 1px white;
  }

}