.menu {
    display: none;
}


.links {
    display: none;
}

.topbar {
    padding-top: 1%;
    /* background-color:antiquewhite; */
}

.contents>.name {
    font-size: 38px;
    font-weight: 800;
    color: #68A5B3;
    justify-content: flex-end;
    text-align: center;
    /* flex:1; */
    cursor: pointer;
    /* padding: 20px; */
}


.menu>.hamburger-bar {
    display: block;
    height: 24px;
    transition: transform 0.2s ease-out;
    text-decoration: none;
    color: black;
    background-color: white;
    padding: 0px 16px;
    text-align: left;

}

.burger-icon {
    cursor: pointer;
    display: inline-block;
    padding: 8px 0;
    top: 4px;
    right: 4px;
    user-select: none;
    width: auto;
    margin: 0;
}

.burger-icon .burger-sticks {
    background: black;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
}

.burger-icon .burger-sticks:before,
.burger-icon .burger-sticks:after {
    background: black;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}

.burger-icon .burger-sticks:before {
    top: 5px;
}

.burger-icon .burger-sticks:after {
    top: -5px;
}

.burger-check {
    display: none;
}

.burger-check:checked~.menu {
    display: flex;
    position: fixed;
    flex-direction: column;
    margin-top: 60px;
    width: 130px;
    height: 100px;
    z-index: 1;
    top: 0;
    right: 0;
    height: 100%;
    max-width: 130px;
    transition: 0.5s ease;
}

.burger-check:checked~.burger-icon .burger-sticks {
    background: transparent;
}

.burger-check:checked~.burger-icon .burger-sticks:before {
    transform: rotate(-45deg);
}

.burger-check:checked~.burger-icon .burger-sticks:after {
    transform: rotate(45deg);
}

.burger-check:checked~.burger-icon:not(.steps) .burger-sticks:before,
.burger-check:checked~.burger-icon:not(.steps) .burger-sticks:after {
    top: 0;
}

@media (min-width:800px) {
    .links {
        display: flex;
        flex: 3.5;
        padding: 20px;
        cursor: pointer
    }

    .burger-icon {
        display: none;
    }
}

@media (min-width:1200px) {
    .links {
        display: flex;
        flex: 3.5;
        justify-content: flex-end;
        padding: 20px;
        cursor: pointer
    }

    .burger-icon {
        display: none;
    }
}